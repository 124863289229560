import { atom } from 'jotai';
import { ReceivablesApplicationHistoryFullOut } from '@repo/common/dtos';
import { IntakeOriginOption } from '@repo/common/utils/intake';

export type IntakeFormData = {
  name: string;
  whatsappNumber: string;
  email: string;
  origin: IntakeOriginOption | '';
  originUserInput?: string;
  cnpj: string;
  isWorkingWithPublicBiddings?: boolean;
}

interface IntakeUserData extends IntakeFormData {
  municipality: string;
  sector: string;
  state: string;
  loanAmount: string;
  fromLoginPage: boolean;
}

export const intakeFormDataAtom = atom<IntakeUserData>({
  whatsappNumber: '',
  cnpj: '',
  municipality: '',
  sector: '',
  state: '',
  loanAmount: '',
  name: '',
  email: '',
  origin: '',
  fromLoginPage: false,
});

export const applicationDataAtom = atom<ReceivablesApplicationHistoryFullOut | null>(null);
