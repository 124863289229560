'use client';

import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { formatPhoneNumberSplit } from '@repo/common/utils/formatters';
import Link from 'next/link';
import { cls } from '@repo/common/utils';
import Logo from '@/BigLogo.svg';
import { useTranslation } from '../i18n/useTranslation';
import BlogLink from './BlogLink';
import { WhatsappContactWrapper } from '../loan/_components/WhatsappContact';

interface Props {
  includeLogo?: boolean;
}

const Footer: React.FC<Props> = ({ includeLogo = true }) => {
  const { t, i18n } = useTranslation();
  const pathname = usePathname();

  return (
    <footer className="bg-white mx-2 my-2 pt-10 pb-3 md:pb-0 md:pt-14 px-8 md:px-20 2xl:px-[20%] rounded-[32px]">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-2 mb-8">
        <div className="flex flex-col gap-1">
          <div>{t('landing.get_in_touch')}</div>
          <a href="mailto:support@otmow.com" className="text-[#2C71F0]">support@otmow.com</a>
          <WhatsappContactWrapper>
            <span className="text-[#2C71F0]">
              {formatPhoneNumberSplit(process.env.NEXT_PUBLIC_WHATSAPP_CONTACT!).phoneNumber}
            </span>
          </WhatsappContactWrapper>
          <div className="text-4xl flex gap-2">
            <Link href="/en-US" className={cls('opacity-30 grayscale', i18n.language === 'en-US' && 'opacity-100 grayscale-0')}>
              🇺🇸
            </Link>
            <Link href="/pt-BR" className={cls('opacity-30 grayscale', i18n.language === 'pt-BR' && 'opacity-100 grayscale-0')}>
              🇧🇷
            </Link>
          </div>
        </div>
        <div>
          <a href="/" className={classNames('block text-black no-underline', pathname !== '/' && 'opacity-40')}>{t('landing.home')}</a>
          <a href="/terms" className={classNames('block text-black no-underline', pathname !== '/terms' && 'opacity-40')}>{t('landing.terms')}</a>
          <a href="/privacy" className={classNames('block text-black no-underline', pathname !== '/privacy' && 'opacity-40')}>{t('landing.privacy_policy')}</a>
          <BlogLink>
            <span className={classNames('block text-black no-underline', pathname !== '/blog' && 'opacity-40')}>{t('landing.header.blog')}</span>
          </BlogLink>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 sm:col-span-2 gap-8 md:gap-2">
          <div className="">
            <div>{t('landing.brazil_office')}</div>
            <div>Avenida Ibirapuera, 2033</div>
            <div>CJ 203</div>
            <div>São Paulo, SP 04029-100</div>
          </div>
          <div className="">
            <div>{t('landing.us_office')}</div>
            <div>614 N. DuPont Highway</div>
            <div>Suite 210</div>
            <div>Dover, DE 19901</div>
          </div>
        </div>
      </div>
      <div className="my-auto">©2024 Ótmow Securitizadora S.A.</div>
      {includeLogo && (
        <Image className="w-full mb-2 mt-[30%]" src={Logo.src} width={Logo.width} height={Logo.height} alt="Conheça a Ótmow" />
      )}
    </footer>
  );
};

export default Footer;
