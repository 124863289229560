'use client';

import Image from 'next/image';
import { useAtom } from 'jotai';
import { usePostHog } from 'posthog-js/react';
import Link from 'next/link';
import React from 'react';
import { useSearchParams } from 'next/navigation';
import { useTranslation } from '@/app/[lang]/i18n/useTranslation';
import { useIsAuth } from '@/lib/auth';
import WhatsappLogo from '../_assets/wpp.svg';
import { intakeFormDataAtom } from '../intake/context';

export default function WhatsappContact() {
  const { t } = useTranslation();
  const queryParams = useSearchParams();
  const [intakeFormData] = useAtom(intakeFormDataAtom);
  const posthog = usePostHog();
  const isAuth = useIsAuth();

  function notifyWhatsappContact() {
    posthog.capture('whatsapp_click', intakeFormData);
  }

  function reportConversion() {
    if (queryParams.has('utm_source') && queryParams.get('utm_source') === 'google') {
      gtag('event', 'conversion_event_contact', {});
    }
  }

  if (isAuth) {
    return (
      <div className="flex items-center space-x-3.5">
        <a
          href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-black text-sm text-center font-medium no-underline p-1.5 flex items-center rounded-full md:rounded-xl hover:bg-black/10 transition"
          style={{ border: '1px solid #A7A7A7' }}
          onClick={() => {
            notifyWhatsappContact();
            reportConversion();
          }}
        >
          <Image src={WhatsappLogo.src} width={24} height={24} alt="Logo WhatsApp" className="lg:mr-1" />
          <span className="hidden lg:inline mr-1">{t('Help')}</span>
        </a>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-3.5">
      <span className="hidden lg:inline-block font-medium text-right text-black/30">{t('Need help?')}</span>
      <a
        href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT}`}
        className="text-black text-sm text-center font-medium no-underline p-1.5 flex items-center rounded-full md:rounded-xl hover:bg-black/10 transition"
        style={{ border: '1px solid #A7A7A7' }}
        onClick={() => notifyWhatsappContact()}
      >
        <Image src={WhatsappLogo.src} width={24} height={24} alt="Logo WhatsApp" className="lg:mr-1" />
        <span className="hidden lg:inline mr-1">{t('Send us a Whatsapp')}</span>
      </a>
    </div>
  );
}

export function WhatsappContactWrapper({ children }: React.PropsWithChildren) {
  const queryParams = useSearchParams();
  const posthog = usePostHog();
  const [intakeFormData] = useAtom(intakeFormDataAtom);

  function notifyWhatsappContact() {
    posthog.capture('whatsapp_click', intakeFormData);
  }
  function reportConversion() {
    if (queryParams.has('utm_source') && queryParams.get('utm_source') === 'google') {
      gtag('event', 'conversion_event_contact', {});
    }
  }

  return (
    <Link
      className="inline-block"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT}`}
      onClick={() => {
        reportConversion();
      }}
    >
      {children}
    </Link>
  );
}
